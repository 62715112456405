import axios from 'axios'
import {API_ENDPOINT} from '../../config'

const all = (instance, params) => {
    instance.setState({ ...instance.state, loadingNasList: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/naslist",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadNasListResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingNasList: false
            })
            alert(error)
        })
}

const handleLoadNasListResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingNasList: false,
                nasList: response.data.data.nasList
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingNasList: false
            })
            alert(response.data.message)
    }
}

const one = (instance, params) => {
    instance.setState({ ...instance.state, loadingNas: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/nas",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadNasResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingNas: false
            })
            alert(error)
        })
}

const handleLoadNasResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingNas: false,
                nas: response.data.data.nas
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingNasList: false
            })
            alert(response.data.message)
    }
}

const NasDatasource = () => {
    return {
        all: (instance, params) => all(instance, params),
        one: (instance, params) => one(instance, params)
    }
}

export default NasDatasource