const getDefaultState = instance => {
    return {
        constraints: instance.props.constraints,
        showAdd: false,
        showEdit: false
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service