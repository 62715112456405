import React from 'react'
import Service from './delete.service'
import Loading from '../../../../../components/loading/loading.component'
import { connect } from 'react-redux'

class DeletePlan extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.deletePlan()
    }

    render() {
        return (
            <div className="delete card">
                <div className="header">
                    <h4>Delete Plan</h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                {
                    this.state.deleting ?
                        <Loading /> :
                        <form onSubmit={this.onSubmit}>
                            <p>Do you really want to delete <strong>{this.props.plan.name}</strong>?</p>
                            <div className="actions">
                            <button type="reset" onClick={this.props.onCancel}>Cancel</button>
                                <button type="submit">Delete</button>
                            </div>
                        </form>
                }
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}


export default connect(mapStateToProps)(DeletePlan)