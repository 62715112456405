import React from 'react'
import { Switch, Route } from "react-router-dom"
import Employees from './employees.page'
import Details from './details/details.page'
import Create from './create/create.component'

const EmployeeController = () => {
    return (
        <div className="employee">
            <Switch>
            <Route path="/folder/employee/create" component={Create} />
                <Route path="/folder/employee/all" component={Employees} />
                <Route path="/folder/employee/:id" component={Details} />
            </Switch>
        </div>
    )
}

export default EmployeeController