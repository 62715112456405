import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import {isValid} from '../common.service'
import {LoadLocations} from '../../../../data/datasource/location.datasource'

const getDefaultState = () => {
    return {
        loading: false,
        saving: false,
        name: "",
        type: "",
        phone: "",
        smsSender: "",
        address: "",
        city: "",
        locationId: "",
        locations: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/salepoint/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            name: instance.state.name,
            locationId: instance.state.locationId,
            phone: instance.state.phone,
            type: instance.state.type,
            address: instance.state.address,
            city: instance.state.city,
            smsSender: instance.state.smsSender
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadLocations: () => LoadLocations(instance),
        isValid: () => isValid(instance),
        save: () => save(instance)
    }
}

export default Service