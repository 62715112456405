import React from 'react'
import { Switch, Route } from "react-router-dom"
import EmployeeController from './employees/employee.controller'
import './common.styles.scss'

const FolderController = () => {
    return (
        <div className="folder">
            <Switch>
                <Route path="/folder/employee" component={EmployeeController} />
            </Switch>
        </div>
    )
}

export default FolderController