import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { isValid } from '../common.service'
import { LoadLocations } from '../../../../data/datasource/location.datasource'

const getDefaultState = () => {
    return {
        loading: false,
        loadingLocations: false,
        saving: false,
        site: {
            name: "",
            area: "",
            locationId: ""
        },
        locations: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadSite = instance => {
    instance.setState({ ...instance.state, loading: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/site",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadSiteResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadSiteResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                site: {
                    id: response.data.data.site.id,
                    name: response.data.data.site.name,
                    area: response.data.data.site.area,
                    locationId: response.data.data.site.location.id
                }
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/site/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            ...instance.state.site,
            locationId: instance.state.site.locationId
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                saving: false,
                flag: {
                    type: "success",
                    text: "Updated successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => isValid(instance),
        loadSite: () => loadSite(instance),
        loadLocations: () => LoadLocations(instance),
        save: () => save(instance)
    }
}

export default Service