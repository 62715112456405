import axios from 'axios'
import { IsValid, onChangeNas, containsNas } from '../common.service'
import ProfileDatasource from '../../../../data/datasource/profile.datasource'
import NasDatasource from '../../../../data/datasource/nas.datasource'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = instance => {
    return {
        loadingProfile: false,
        saving: false,
        loadingNasList: false,
        profile: {
            id: instance.props.match.params.id,
            name: "",
            description: "",
            rateLimit: "",
            ipPool: "",
            nasProfiles: []
        },
        nasList: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadProfile = instance => {
    ProfileDatasource().one(instance, {
        id: instance.props.match.params.id
    })
}

const loadNasList = instance => {
    NasDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {}
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/profile/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.profile
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                saving: false,
                flag: {
                    type: "success",
                    text: "Updated successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => IsValid(instance),
        loadProfile: () => loadProfile(instance),
        loadNasList: () => loadNasList(instance),
        onChangeNas: nasId => onChangeNas(instance, nasId),
        containsNas: nasId => containsNas(instance, nasId),
        save: () => save(instance)
    }
}

export default Service