import React from 'react'
import { Switch, Route } from "react-router-dom"
import Profiles from './profiles.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const ProfileController = () => {
    return (
        <div className="profile">
            <Switch>
                <Route path="/internet/profile/create" component={Create} />
                <Route path="/internet/profile/all" component={Profiles} />
                <Route path="/internet/profile/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default ProfileController