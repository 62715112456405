import { isValid } from '../common.service'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        loadingDepartments: true,
        loadingLgas: true,
        loadingNationalities: true,
        employee: {
            staffId: "",
            confidentialFileId: "",
            name: "",
            sex: "",
            dob: "",
            phone: "",
            email: "",
            address: "",
            nextOfKinName: "",
            nextOfKinPhone: "",
            salaryGradeLevel: "",
            qualifications: "",
            position: "",
            status: "",
            departmentId: "",
            lgaId: "",
            nationalityId: "",
            appointmentDate: "",
            firstPromotionDate: "",
            lastPromotionDate: ""
        },
        departments: [],
        lgas: [],
        nationalities: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadDepartments = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/departments",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLoadDepartmentsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingDepartments: false
            })
            alert(error)
        })
}

const handleLoadDepartmentsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDepartments: false,
                departments: response.data.data.departments
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingDepartments: false
            })
            alert(response.data.message)
    }
}

const loadNationalities = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/nationalities",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLoadNationalitiesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingNationalities: false
            })
            alert(error)
        })
}

const handleLoadNationalitiesResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingNationalities: false,
                nationalities: response.data.data.nationalities
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingNationalities: false
            })
            alert(response.data.message)
    }
}

const loadLgas = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/lgas",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLgasResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingLgas: false
            })
            alert(error)
        })
}

const handleLgasResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingLgas: false,
                lgas: response.data.data.lgas
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingLgas: false
            })
            alert(response.data.message)
    }
}

const create = (instance) => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            ...instance.state.employee,
            status: Number(instance.state.employee.status),
            dob: new Date(instance.state.employee.dob).getTime() / 1000,
            appointmentDate: new Date(instance.state.employee.appointmentDate).getTime() / 1000,
            firstPromotionDate: new Date(instance.state.employee.firstPromotionDate).getTime() / 1000,
            lastPromotionDate: new Date(instance.state.employee.lastPromotionDate).getTime() / 1000
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                saving: false,
                employee: {
                    staffId: "",
                    confidentialFileId: "",
                    name: "",
                    sex: "",
                    dob: "",
                    phone: "",
                    email: "",
                    address: "",
                    nextOfKinName: "",
                    nextOfKinPhone: "",
                    salaryGradeLevel: "",
                    qualifications: "",
                    position: "",
                    status: "",
                    departmentId: "",
                    lgaId: "",
                    nationalityId: "",
                    appointmentDate: "",
                    firstPromotionDate: "",
                    lastPromotionDate: ""
                },
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDepartments: () => loadDepartments(instance),
        loadLgas: () => loadLgas(instance),
        loadNationalities: () => loadNationalities(instance),
        isValid: () => isValid(instance),
        create: () => create(instance)
    }
}

export default Service