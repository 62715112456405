import { isValid } from '../../../common.service'
import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../config'

const getDefaultState = instance => {
    return {
        saving: false,
        loadingDepartments: true,
        loadingLgas: true,
        uploading: false,
        loadingNationalities: true,
        employee: {
            ...instance.props.employee,
            departmentId: instance.props.employee.department.id,
            lgaId: instance.props.employee.lga.id,
            nationalityId: instance.props.employee.nationality.id,
            dob: new Date(instance.props.employee.dob*1000).toString("yyyy-MM-dd"),
            appointmentDate: instance.props.employee.appointmentDate !== ''? new Date(instance.props.employee.appointmentDate*1000).toString("yyyy-MM-dd"):"",
            firstPromotionDate: instance.props.employee.firstPromotionDate !== ''? new Date(instance.props.employee.firstPromotionDate*1000).toString("yyyy-MM-dd"):"",
            lastPromotionDate: instance.props.employee.lastPromotionDate !== ''? new Date(instance.props.employee.lastPromotionDate*1000).toString("yyyy-MM-dd"):""
        },
        departments: [],
        lgas: [],
        nationalities: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadDepartments = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/departments",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLoadDepartmentsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingDepartments: false
            })
            alert(error)
        })
}

const handleLoadDepartmentsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingDepartments: false,
                departments: response.data.data.departments
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingDepartments: false
            })
            alert(response.data.message)
    }
}

const loadNationalities = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/nationalities",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLoadNationalitiesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingNationalities: false
            })
            alert(error)
        })
}

const handleLoadNationalitiesResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingNationalities: false,
                nationalities: response.data.data.nationalities
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingNationalities: false
            })
            alert(response.data.message)
    }
}

const loadLgas = instance => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/lgas",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: {}
        }
    }).then(response => handleLgasResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingLgas: false
            })
            alert(error)
        })
}

const handleLgasResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingLgas: false,
                lgas: response.data.data.lgas
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingLgas: false
            })
            alert(response.data.message)
    }
}

const save = (instance) => {

    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            ...instance.state.employee,
            status: Number(instance.state.employee.status),
            dob: instance.state.employee.dob,
            appointmentDate: instance.state.employee.appointmentDate,
            firstPromotionDate: instance.state.employee.firstPromotionDate,
            lastPromotionDate: instance.state.employee.lastPromotionDate
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            instance.props.onEmployeeReload(response.data.data.employee)
            instance.props.onClose()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const uploadPhoto = (instance, data) => {
    axios({
        method: 'post',
        url: API_ENDPOINT + "/employee/photo/upload",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: data
    }).then(response => handleUploadPhotoResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(error)
        })
}

const handleUploadPhotoResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                uploading: false,
                employee: {
                    ...instance.state.employee,
                    photo: response.data.data.photo
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                uploading: false
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadDepartments: () => loadDepartments(instance),
        loadLgas: () => loadLgas(instance),
        loadNationalities: () => loadNationalities(instance),
        isValid: () => isValid(instance),
        uploadPhoto: data => uploadPhoto(instance, data),
        save: () => save(instance)
    }
}

export default Service