import React from 'react'
import { Switch } from "react-router-dom"
import './common.styles.scss'

const SettingController = () => {
    return (
        <div className="settings">
            <Switch>
                
            </Switch>
        </div>
    )
}

export default SettingController