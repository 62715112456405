import Validation from '../../../../../../../data/validation/validation'
import { StringValidationRule } from '../../../../../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../../../../../utils/UIHelper'
import ProfileDatasource from '../../../../../../../data/datasource/profile.datasource'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.profileId, (error) => errors.profileId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.startH, (error) => errors.start = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.startM, (error) => errors.start = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.startS, (error) => errors.start = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.endH, (error) => errors.end = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.endM, (error) => errors.end = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.endS, (error) => errors.end = error, { min: { value: 2, error: "Invalid" }, max: { value: 2, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const loadProfiles = instance => {
    ProfileDatasource().all(instance, {
        offset: 0,
        limit: 100,
        criteria: {}
    })
}

export const onChangeDay = (instance, day) => {
    let days = instance.state.days
    if (containsDay(instance, day)) {
        days = instance.state.days.filter(_day => _day !== day)
    }
    else {
        days.push(day)
    }

    instance.setState({
        ...instance.state,
        days: days
    })
}

export const containsDay = (instance, day) =>  {
    return instance.state.days.indexOf(day) > -1
}
