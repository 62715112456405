import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="nasname">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.profile.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.profile.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="rateLimit">
                    <div className="form-label">
                        <label>Rate Limit</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="rateLimit" value={props.profile.rateLimit} onChange={props.onChange} />
                        <span className="error">{props.errors.rateLimit}</span>
                    </div>
                </div>
                <div className="row" id="ipPool">
                    <div className="form-label">
                        <label>IP Pool</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="ipPool" value={props.profile.ipPool} onChange={props.onChange} />
                        <span className="error">{props.errors.ipPool}</span>
                    </div>
                </div>
                {props.children}
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/internet/profile/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)