import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="shortname">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="shortname" value={props.nas.shortname} onChange={props.onChange} />
                        <span className="error">{props.errors.shortname}</span>
                    </div>
                </div>
                <div className="row" id="nasname">
                    <div className="form-label">
                        <label>IP Address</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="nasname" value={props.nas.nasname} onChange={props.onChange} />
                        <span className="error">{props.errors.nasname}</span>
                    </div>
                </div>
                <div className="row" id="ports">
                    <div className="form-label">
                        <label>Port</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="ports" value={props.nas.ports} onChange={props.onChange} />
                        <span className="error">{props.errors.ports}</span>
                    </div>
                </div>
                <div className="row" id="secret">
                    <div className="form-label">
                        <label>Secret</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="secret" value={props.nas.secret} onChange={props.onChange} />
                        <span className="error">{props.errors.secret}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.nas.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/internet/nas/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)