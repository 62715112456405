import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                {
                    typeof props.type !== "undefined" && <div className="row" id="type">
                        <div className="form-label">
                            <label>Type</label>
                        </div>
                        <div className="form-input">
                            <select name="type" onChange={props.onChange} value={props.type}>
                                <option value=""></option>
                                <option value="1">Instant Access</option>
                                <option value="2">Prepaid</option>
                            </select>
                            <span className="error">{props.errors.type}</span>
                        </div>
                    </div>
                }
                <div className="row" id="phone">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="phone" value={props.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                <div className="row" id="smsSender">
                    <div className="form-label">
                        <label>Sms Sender</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="smsSender" value={props.smsSender} onChange={props.onChange} />
                        <span className="error">{props.errors.smsSender}</span>
                    </div>
                </div>
                <div className="row" id="address">
                    <div className="form-label">
                        <label>Address</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="address" value={props.address} onChange={props.onChange} />
                        <span className="error">{props.errors.address}</span>
                    </div>
                </div>
                <div className="row" id="city">
                    <div className="form-label">
                        <label>City</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="city" value={props.city} onChange={props.onChange} />
                        <span className="error">{props.errors.city}</span>
                    </div>
                </div>
                <div className="row" id="locationId">
                    <div className="form-label">
                        <label>Location</label>
                    </div>
                    <div className="form-input">
                        <select name="locationId" onChange={props.onChange} value={props.locationId}>
                            <option value=""></option>
                            {
                                props.locations.map((location, key) => <option key={key} value={location.id}>{location.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.locationId}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/internet/salepoints/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)