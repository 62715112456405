import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Payers from './charts/payers/payers.component'
import './dashboard.styles.scss'
import PayerReport from './payer/payer.component'

class DashboardPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            ...props
        }
    }

    render() {
        return (
            <div className="dashboard">
                <div className="header">
                    <h4>Dashboard</h4>
                </div>
                <PayerReport/>
                <br/>
                <Payers/>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(DashboardPage))
