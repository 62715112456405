import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.site.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.site.area, (error) => instance.state.errors.area = error, { min: { value: 2, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.site.locationId, (error) => instance.state.errors.locationId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}