import Validation from '../../../data/validation/validation'
import { StringValidationRule, PhoneValidationRule, EmailValidationRule, NumberValidationRule, DateValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.employee.name, (error) => instance.state.errors.name = error, { min: { value: 3, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.sex, (error) => instance.state.errors.sex = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    validation.addValidationRule(DateValidationRule, instance.state.employee.dob, (error) => instance.state.errors.dob = error, { allowNull: false })
    validation.addValidationRule(PhoneValidationRule, instance.state.employee.phone, (error) => instance.state.errors.phone = error, { allowNull: true })
    validation.addValidationRule(EmailValidationRule, instance.state.employee.email, (error) => instance.state.errors.email = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.employee.address, (error) => instance.state.errors.address = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.nextOfKinName, (error) => instance.state.errors.nextOfKinName = error, { min: { value: 0, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.employee.nextOfKinPhone, (error) => instance.state.errors.nextOfKinPhone = error, { allowNull: true })
    validation.addValidationRule(NumberValidationRule, instance.state.employee.salaryGradeLevel, (error) => instance.state.errors.salaryGradeLevel = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.qualifications, (error) => instance.state.errors.qualifications = error, { min: { value: 0, error: "Invalid" }, max: { value: 300, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.position, (error) => instance.state.errors.position = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.departmentId, (error) => instance.state.errors.departmentId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.lgaId, (error) => instance.state.errors.lgaId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.employee.nationalityId, (error) => instance.state.errors.nationalityId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}