import axios from 'axios'
import { IsValid, onChangeNas, containsNas } from '../common.service'
import NasDatasource from '../../../../data/datasource/nas.datasource'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        loadingNasList: false,
        profile: {
            name: "",
            description: "",
            rateLimit: "",
            ipPool: "",
            nasProfiles: []
        },
        nasList: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadNasList = instance => {
    NasDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {}
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/profile/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.profile
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            let nasList = instance.state.nasList
            instance.setState({
                ...getDefaultState(),
                nasList: nasList,
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => IsValid(instance),
        loadNasList: () => loadNasList(instance),
        onChangeNas: nasId => onChangeNas(instance, nasId),
        containsNas: nasId => containsNas(instance, nasId),
        save: () => save(instance)
    }
}

export default Service