import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { loadRoles, isValid, onChangeUserRole, containsUserRole } from '../common.service'

const getDefaultState = () => {
    return {
        saving: false,
        user: {
            firstname: "",
            lastname: "",
            phone: "",
            username: "",
            password: "",
            showUsername: true,
            userRoles: []
        },
        roles: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const save = instance => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.user
    }).then(response => handleSaveResponse(instance, response))
    .catch(error => {
        instance.setState({
            ...instance.state,
            saving: false
        })
        alert(error)
    })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            let roles = instance.state.roles
            instance.setState({
                ...getDefaultState(),
                roles: roles,
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully, OTP Key: "+ response.data.data.secret
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadRoles: () => loadRoles(instance),
        isValid: () => isValid(instance),
        onChangeUserRole: roleId => onChangeUserRole(instance, roleId),
        containsUserRole: roleId => containsUserRole(instance, roleId),
        save: () => save(instance)
    }
}

export default Service