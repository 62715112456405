import axios from 'axios'
import { LoadLocations } from '../../../../data/datasource/location.datasource'
import { LoadSalePoints } from '../../../../data/datasource/salepoint.datasource'
import ProfileDatasource from '../../../../data/datasource/profile.datasource'
import { API_ENDPOINT } from '../../../../config'
import { IsValid, onChangeLocation, onChangeSalePoint, containsLocation, containsSalePoint } from '../common.service'

const getDefaultState = instance => {
    return {
        loading: false,
        saving: false,
        loadingLocations: false,
        loadingSalePoints: false,
        plan: {
            name: "",
            description: "",
            profileId: "",
            expiredProfileId: "",
            type: instance.props.match.path.includes("prepaid") ? 1 : 2,
            amount: "",
            contractValue: "",
            uptime: "",
            validity: "",
            locationPlans: [],
            salePointPlans: [],
            constraints: []
        },
        locations: [],
        salePoints: [],
        profiles: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadProfiles = instance => {
    ProfileDatasource().all(instance, {
        offset: 0,
        limit: 1000,
        criteria: {}
    })
}

const loadPlan = instance => {
    instance.setState({ ...instance.state, loadingPlan: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/plan",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            id: instance.props.match.params.id
        }
    }).then(response => handleLoadPlanResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingPlan: false
            })
            alert(error)
        })
}

const handleLoadPlanResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                plan: {
                    ...response.data.data.plan,
                    profileId: response.data.data.plan.profile.id,
                    expiredProfileId: response.data.data.plan.expiredProfile !== null ? response.data.data.plan.expiredProfile.id : ""
                },
                loadingPlan: false
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingPlan: false
            })
            alert(response.data.message)
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/plan/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.plan
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => IsValid(instance),
        loadLocations: () => LoadLocations(instance),
        loadSalePoints: () => LoadSalePoints(instance),
        loadPlan: () => loadPlan(instance),
        loadProfiles: () => loadProfiles(instance),
        onChangeLocation: locationId => onChangeLocation(instance, locationId),
        onChangeSalePoint: salePointId => onChangeSalePoint(instance, salePointId),
        containsLocation: locationId => containsLocation(instance, locationId),
        containsSalePoint: salePointId => containsSalePoint(instance, salePointId),
        save: () => save(instance)
    }
}

export default Service