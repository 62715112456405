import Validation from '../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.nas.nasname, (error) => instance.state.errors.nasname = error, { min: { value: 2, error: "Invalid" }, max: { value: 128, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.nas.shortname, (error) => instance.state.errors.shortname = error, { min: { value: 2, error: "Invalid" }, max: { value: 32, error: "Invalid" } })
    validation.addValidationRule(NumberValidationRule, instance.state.nas.ports, (error) => instance.state.errors.ports = error, { min: { value: 1, error: "Invalid" }, max: { value: 6000, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.nas.secret, (error) => instance.state.errors.secret = error, { min: { value: 1, error: "Invalid" }, max: { value: 60, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.nas.description, (error) => instance.state.errors.description = error, { min: { value: 2, error: "Invalid" }, max: { value: 200, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}