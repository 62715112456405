import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div>
                    <div className="row" id="staffId">
                        <div className="form-label">
                            <label>Staff ID</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="staffId" value={props.employee.staffId} onChange={props.onChange} />
                            <span className="error">{props.errors.staffId}</span>
                        </div>
                    </div>
                    <div className="row" id="confidentialFileId">
                        <div className="form-label">
                            <label>Confidential File ID</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="confidentialFileId" value={props.employee.confidentialFileId} onChange={props.onChange} />
                            <span className="error">{props.errors.confidentialFileId}</span>
                        </div>
                    </div>
                    <div className="row" id="name">
                        <div className="form-label">
                            <label>Name</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="name" value={props.employee.name} onChange={props.onChange} />
                            <span className="error">{props.errors.name}</span>
                        </div>
                    </div>
                    <div className="row" id="sex">
                        <div className="form-label">
                            <label>Sex</label>
                        </div>
                        <div className="form-input">
                            <select name="sex" onChange={props.onChange} value={props.employee.sex}>
                                <option value=""></option>
                                <option value="m">Male</option>
                                <option value="f">Female</option>
                            </select>
                            <span className="error">{props.errors.sex}</span>
                        </div>
                    </div>
                    <div className="row" id="dob">
                        <div className="form-label">
                            <label>Date of Birth</label>
                        </div>
                        <div className="form-input">
                            <input type="date" name="dob" value={props.employee.dob} onChange={props.onChange} />
                            <span className="error">{props.errors.dob}</span>
                        </div>
                    </div>
                    <div className="row" id="phone">
                        <div className="form-label">
                            <label>Phone</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="phone" value={props.employee.phone} onChange={props.onChange} />
                            <span className="error">{props.errors.phone}</span>
                        </div>
                    </div>
                    <div className="row" id="email">
                        <div className="form-label">
                            <label>Email</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="email" value={props.employee.email} onChange={props.onChange} />
                            <span className="error">{props.errors.email}</span>
                        </div>
                    </div>
                    <div className="row" id="address">
                        <div className="form-label">
                            <label>Address</label>
                        </div>
                        <div className="form-input">
                            <textarea type="text" name="address" value={props.employee.address} onChange={props.onChange} />
                            <span className="error">{props.errors.address}</span>
                        </div>
                    </div>
                    <div className="row" id="nextOfKinName">
                        <div className="form-label">
                            <label>Next Of Kin Name</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="nextOfKinName" value={props.employee.nextOfKinName} onChange={props.onChange} />
                            <span className="error">{props.errors.nextOfKinName}</span>
                        </div>
                    </div>
                    <div className="row" id="nextOfKinPhone">
                        <div className="form-label">
                            <label>Next Of Kin Phone</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="nextOfKinPhone" value={props.employee.nextOfKinPhone} onChange={props.onChange} />
                            <span className="error">{props.errors.nextOfKinPhone}</span>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="row" id="appointmentDate">
                        <div className="form-label">
                            <label>Appointment Date</label>
                        </div>
                        <div className="form-input">
                            <input type="date" name="appointmentDate" value={props.employee.appointmentDate} onChange={props.onChange} />
                            <span className="error">{props.errors.appointmentDate}</span>
                        </div>
                    </div>
                    <div className="row" id="firstPromotionDate">
                        <div className="form-label">
                            <label>First Promotion Date</label>
                        </div>
                        <div className="form-input">
                            <input type="date" name="firstPromotionDate" value={props.employee.firstPromotionDate} onChange={props.onChange} />
                            <span className="error">{props.errors.firstPromotionDate}</span>
                        </div>
                    </div>
                    <div className="row" id="lastPromotionDate">
                        <div className="form-label">
                            <label>Last Promotion Date</label>
                        </div>
                        <div className="form-input">
                            <input type="date" name="lastPromotionDate" value={props.employee.lastPromotionDate} onChange={props.onChange} />
                            <span className="error">{props.errors.lastPromotionDate}</span>
                        </div>
                    </div>
                    <div className="row" id="salaryGradeLevel">
                        <div className="form-label">
                            <label>Salary Grade Level</label>
                        </div>
                        <div className="form-input">
                            <input type="number" name="salaryGradeLevel" value={props.employee.salaryGradeLevel} onChange={props.onChange} />
                            <span className="error">{props.errors.salaryGradeLevel}</span>
                        </div>
                    </div>
                    <div className="row" id="qualifications">
                        <div className="form-label">
                            <label>Qualifications</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="qualifications" value={props.employee.qualifications} onChange={props.onChange} />
                            <span className="error">{props.errors.qualifications}</span>
                        </div>
                    </div>
                    <div className="row" id="position">
                        <div className="form-label">
                            <label>Current Position</label>
                        </div>
                        <div className="form-input">
                            <input type="text" name="position" value={props.employee.position} onChange={props.onChange} />
                            <span className="error">{props.errors.position}</span>
                        </div>
                    </div>
                    <div className="row" id="departmentId">
                        <div className="form-label">
                            <label>Department</label>
                        </div>
                        <div className="form-input">
                            <select name="departmentId" onChange={props.onChange} value={props.employee.departmentId}>
                                <option value=""></option>
                                {
                                    props.departments.map((department, key) => <option key={key} value={department.id}>{department.name}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.departmentId}</span>
                        </div>
                    </div>
                    <div className="row" id="lgaId">
                        <div className="form-label">
                            <label>Lga</label>
                        </div>
                        <div className="form-input">
                            <select name="lgaId" onChange={props.onChange} value={props.employee.lgaId}>
                                <option value=""></option>
                                {
                                    props.lgas.map((lga, key) => <option key={key} value={lga.id}>{lga.name}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.lgaId}</span>
                        </div>
                    </div>
                    <div className="row" id="nationalityId">
                        <div className="form-label">
                            <label>Nationality</label>
                        </div>
                        <div className="form-input">
                            <select name="nationalityId" onChange={props.onChange} value={props.employee.nationalityId}>
                                <option value=""></option>
                                {
                                    props.nationalities.map((nationality, key) => <option key={key} value={nationality.id}>{nationality.name}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.nationalityId}</span>
                        </div>
                    </div>
                    <div className="row" id="status">
                        <div className="form-label">
                            <label>Status</label>
                        </div>
                        <div className="form-input">
                            <select name="status" onChange={props.onChange} value={String(props.employee.status)}>
                                <option value=""></option>
                                <option value="0">Active</option>
                                <option value="1">Retired</option>
                                <option value="6">Voluntary Retired</option>
                                <option value="2">Resigned</option>
                                <option value="3">Deceased</option>
                                <option value="4">On Leave of Absence</option>
                                <option value="5">On Study Leave</option>
                                <option value="7">Dismissed</option>
                            </select>
                            <span className="error">{props.errors.status}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Create</button>
            </div>
        </form>
    )
}

export default withRouter(Form)