import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import Pagination from '../../../components/pagination/pagination.component'
import './employees.styles.scss'
import Service from './employees.service'
import { API_ENDPOINT } from '../../../config'
import Filters from './filters.component'

class Employees extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadEmployees()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadEmployees())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadEmployees()
    }

    render() {
        return (
            <>
                {this.state.loadingEmployees && <Dialog><Loading /></Dialog>}
                <div className="employees">
                    <div className="header">
                        <h4>Employees</h4>
                        <div className="buttons">
                        <Link className="btn" to={"/folder/employee/create"}>Create</Link>
                            <a className="btn" rel="noreferrer" target="_blank" href={API_ENDPOINT + "/employee/export"}>Export</a>
                        </div>
                        <Filters filter={this.state.filter} onSubmit={this.onFilterSubmit} onChange={this.onFilterChange} />
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Conf. ID</th>
                                <th>Staff ID</th>
                                <th>Name</th>
                                <th>Appointment Date</th>
                                <th>Current Designation</th>
                                <th>SGL</th>
                                <th>Updated</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.employees.map((employee, key) => <tr key={key} onClick={() => this.props.history.push('/folder/employee/' + employee.id)}>
                                    <td>{employee.confidentialFileId}</td>
                                    <td>{employee.staffId}</td>
                                    <td>{employee.name}</td>
                                    <td>{new Date(employee.appointmentDate * 1000).toString("dd/MMM/yyyy")}</td>
                                    <td>{employee.position}</td>
                                    <td>{employee.salaryGradeLevel}</td>
                                    <td>{new Date(employee.updated * 1000).toString("dd MMM yyyy HH:mm:ss")}</td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Employees))