import React from 'react'
import './info.styles.scss'
import Service from './info.service'
import { connect } from 'react-redux'
import AuthHelper from '../../../../../../utils/AuthHelper'
import imageAvatar from '../../../../../../assets/images/avatar.png'

class Info extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    onShowEditClick = customerPlan => {
        this.setState({
            ...this.state,
            customerPlan: customerPlan,
            showEdit: true
        })
    }

    toggleShowEdit = () => this.setState({
        ...this.state,
        showEdit: !this.state.showEdit
    })

    getStatusLabel = status => {
        switch (status) {
            case 0:
                return "Active"
            case 1:
                return "Retired"
            case 2:
                return "Resigned"
            case 3:
                return "Deceased"
            case 4:
                return "On Leave of Absence"
            case 5:
                return "On Study Leave"
            case 6:
                return "Voluntary Retired"
            case 7:
                return "Dismissed"
            default:
                return "Unknown"
        }
    }

    render() {
        let authHelper = AuthHelper(this.props.auth)
        return (
            <div className="info">
                <div className="cols">
                    <div className="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Confidential File ID</td>
                                    <td>{this.props.confidentialFileId}</td>
                                </tr>
                                <tr>
                                    <td>Staff ID</td>
                                    <td>{this.props.staffId}</td>
                                </tr>
                                <tr>
                                    <td>Name</td>
                                    <td>{this.props.name}</td>
                                </tr>
                                <tr>
                                    <td>Sex</td>
                                    <td>{this.props.sex === "m" ? "Male" : "Female"}</td>
                                </tr>
                                <tr>
                                    <td>Date of Birth</td>
                                    <td>{new Date(this.props.dob * 1000).toString("dd/MM/yyyy")}</td>
                                </tr>
                                <tr>
                                    <td>Phone</td>
                                    <td>{this.props.phone}</td>
                                </tr>
                                <tr>
                                    <td>Email</td>
                                    <td>{this.props.email}</td>
                                </tr>
                                <tr>
                                    <td>Address</td>
                                    <td>{this.props.address}</td>
                                </tr>
                                <tr>
                                    <td>Next of Kin Name</td>
                                    <td>{this.props.nextOfKinName}</td>
                                </tr>
                                <tr>
                                    <td>Next of Kin Phone</td>
                                    <td>{this.props.nextOfKinPhone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <table>
                            <tbody>
                                <tr>
                                    <td>Status</td>
                                    <td>{this.getStatusLabel(this.props.status)}</td>
                                </tr>
                                <tr>
                                    <td>Position</td>
                                    <td>{this.props.position}</td>
                                </tr>
                                <tr>
                                    <td>Appointment Date</td>
                                    <td>{this.props.appointmentDate !== null ? new Date(this.props.appointmentDate * 1000).toString("dd/MM/yyyy") : ""}</td>
                                </tr>
                                <tr>
                                    <td>First Promotion Date</td>
                                    <td>{this.props.firstPromotionDate !== null ? new Date(this.props.firstPromotionDate * 1000).toString("dd/MM/yyyy") : ""}</td>
                                </tr>
                                <tr>
                                    <td>Last Promotion Date</td>
                                    <td>{this.props.lastPromotionDate !== null ? new Date(this.props.lastPromotionDate * 1000).toString("dd/MM/yyyy") : ""}</td>
                                </tr>
                                <tr>
                                    <td>Salary Grade Level</td>
                                    <td>{this.props.salaryGradeLevel}</td>
                                </tr>
                                <tr>
                                    <td>Department</td>
                                    <td>{this.props.department.name}</td>
                                </tr>
                                <tr>
                                    <td>Nationality</td>
                                    <td>{this.props.nationality.name}</td>
                                </tr>
                                <tr>
                                    <td>LGA</td>
                                    <td>{this.props.lga.name}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="col">
                        <img src={this.props.photo !== null ? this.props.photo : imageAvatar} alt="" />
                    </div>
                </div>
                <div className="header">
                    <div className="buttons">
                        {authHelper.canAddCustomerPlan() && <button className="btn">Add Promotion</button>}
                        {authHelper.canChangeCustomerPlan() && <button className="btn">Change Staff ID</button>}
                        {authHelper.canChangeCustomerPlan() && <button className="btn">Update Status</button>}
                        {authHelper.canEditCustomer() && <button className="btn" onClick={this.props.onEditClick}>Edit Data</button>}

                    </div>
                </div>
                <div className="header">
                    <h4>Promotions</h4>
                </div>
                <table>
                    <thead>
                        <tr>
                            <th>Position</th>
                            <th>Effective Date</th>
                            <th>SGL</th>
                            <th>Created</th>
                            {(authHelper.canModifyCustomerPlan() || authHelper.canDeleteCustomerPlan()) && <th>Actions</th>}
                        </tr>
                    </thead>
                    <tbody>
                        {

                        }
                    </tbody>
                </table>

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(Info)