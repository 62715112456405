import Validation from '../../../data/validation/validation'
import { StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.profile.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 64, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.profile.description, (error) => instance.state.errors.description = error, { min: { value: 2, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.profile.rateLimit, (error) => instance.state.errors.rateLimit = error, { min: { value: 4, error: "Invalid" }, max: { value: 50, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.profile.ipPool, (error) => instance.state.errors.ipPool = error, { min: { value: 0, error: "Invalid" }, max: { value: 20, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const onChangeNas = (instance, nasId) => {
    let nasProfiles = instance.state.profile.nasProfiles
    if (containsNas(instance, nasId)) {
        nasProfiles = instance.state.profile.nasProfiles.filter(nasProfile => nasProfile.nas.id !== nasId)
    }
    else {
        nasProfiles.push({
            nas: {
                id: nasId
            }
        })
    }
    instance.setState({
        ...instance.state,
        profile: {
            ...instance.state.profile,
            nasProfiles: nasProfiles
        }
    })
}

export const containsNas = (instance, nasId) =>  {
    for(let i = 0; i < instance.state.profile.nasProfiles.length; i++){
        if(instance.state.profile.nasProfiles[i].nas.id === nasId){
            return true
        }
    }
    return false
}