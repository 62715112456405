import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <br /><br />
            <div className="inputs">
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.plan.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="description">
                    <div className="form-label">
                        <label>Description</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="description" value={props.plan.description} onChange={props.onChange} />
                        <span className="error">{props.errors.description}</span>
                    </div>
                </div>
                <div className="row" id="profileId">
                    <div className="form-label">
                        <label>Profile</label>
                    </div>
                    <div className="form-input">
                        <select name="profileId" onChange={props.onChange} value={props.plan.profileId}>
                            <option value=""></option>
                            {
                                props.profiles.map((profile, key) => <option key={key} value={profile.id}>{profile.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.profileId}</span>
                    </div>
                </div>
                {
                    props.plan.type === 1 && <div className="row" id="expiredProfileId">
                        <div className="form-label">
                            <label>Expired Profile</label>
                        </div>
                        <div className="form-input">
                            <select name="expiredProfileId" onChange={props.onChange} value={props.plan.expiredProfileId}>
                                <option value=""></option>
                                {
                                    props.profiles.map((profile, key) => <option key={key} value={profile.id}>{profile.name}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.expiredProfileId}</span>
                        </div>
                    </div>
                }
                <div className="row" id="amount">
                    <div className="form-label">
                        <label>Amount</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="amount" value={props.plan.amount} onChange={props.onChange} />
                        <span className="error">{props.errors.amount}</span>
                    </div>
                </div>
                {
                    props.plan.type === 2 && <>
                        <div className="row" id="contractValue">
                            <div className="form-label">
                                <label>Contract Value</label>
                            </div>
                            <div className="form-input">
                                <input type="number" name="contractValue" value={props.plan.contractValue} onChange={props.onChange} />
                                <span className="error">{props.errors.contractValue}</span>
                            </div>
                        </div>
                        <div className="row" id="uptime">
                            <div className="form-label">
                                <label>Uptime</label>
                            </div>
                            <div className="form-input">
                                <input type="number" name="uptime" value={props.plan.uptime} onChange={props.onChange} />
                                <span className="error">{props.errors.uptime}</span>
                            </div>
                        </div>
                    </>
                }
                <div className="row" id="validity">
                    <div className="form-label">
                        <label>Validity</label>
                    </div>
                    <div className="form-input">
                        <input type="number" name="validity" value={props.plan.validity} onChange={props.onChange} />
                        <span className="error">{props.errors.validity}</span>
                    </div>
                </div>
            </div>
            {props.children}
            <div className="actions">
                <button type="reset" onClick={() => props.history.push(props.plan.type === 1 ? '/internet/plans/prepaid' : '/internet/plans/instant-access')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)