import iconDashboard from '../../../assets/icons/dashboard.svg'
import iconPayer from '../../../assets/icons/payer.svg'
import iconSettings from '../../../assets/icons/config.svg'

const DefaultStore = {
    showMenu: false,
    activeItem: 0,
    items: [{
            text: "Dashboard",
            icon: iconDashboard,
            permissions: [],
            subItems: []
        },
        {
            text: "Folders",
            icon: iconPayer,
            permissions: [1,21,22,27,82,84],
            subItems: [
                {
                    url: "/folder/employee/all",
                    text: "Employees",
                    permissions: [1,82,84]
                },
                {
                    url: "/folder/department/all",
                    text: "Departments",
                    permissions: [1,21,22,27],
                }
            ]
        },
        {
            text: "Security",
            icon: iconSettings,
            permissions: [1],
            subItems: [{
                    url: "/security/users/all",
                    text: "Users",
                    permissions: [1]
                },
                {
                    url: "/security/roles/all",
                    text: "Roles",
                    permissions: [1]
                }
            ]
        }
    ]
}

export default DefaultStore