import SiteDatasource from '../../../data/datasource/site.datasource'

const getDefaultState = () => {
    return {
        loadingSites: false,
        sites: [],
        filter: {
            column: "",
            value: ""
        },
        showDelete: false,
        siteToDelete: null,
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadSites = instance => {
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    SiteDatasource().all(instance, {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: criteria
    })
}

const Service = instance => {
    return {
        loadSites: () => loadSites(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service