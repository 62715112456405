import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './edit.service'
import './edit.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Form from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'
import Constraint from './components/constraint/constraint.component'

class Edit extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        if (this.state.plan.type === 1) {
            this.service.loadLocations()
        }
        else {
            this.service.loadSalePoints()
        }
        this.service.loadProfiles()
        this.service.loadPlan()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    onChangeLocation = (locationId, checked) => this.service.onChangeLocation(locationId)

    onChangeSalePoint = (salePointId, checked) => this.service.onChangeSalePoint(salePointId)

    render() {
        return (
            <div className="edit">
                {(this.state.saving || this.state.loadLocations || this.state.loadingSalePoints || this.state.loadingPlan) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Edit {this.state.plan.type === 1 ? "Prepaid" : "Instant Access"} Plan</h4>
                </div>
                <div className="card">
                    <div className="col">
                        <Form {...this.state} onChange={FormHelper(this, "plan").onChange} onSubmit={this.onSubmit} onLocationChanged={this.onLocationChanged}>
                            <div className={"flag " + this.state.flag.type}>
                                {this.state.flag.text}
                            </div>
                            {
                                this.state.plan.type === 1 ? <>
                                    <h4>Allowed Locations</h4>
                                    <div className="row-locations">
                                        {
                                            this.state.locations.map((location, key) => <Checkbox key={key} text={location.name} name={location.id} checked={this.service.containsLocation(location.id)} onChange={this.onChangeLocation} />)
                                        }
                                    </div>
                                </> : <>
                                    <h4>Allowed SalePoints</h4>
                                    <div className="row-salepoints">
                                        {
                                            this.state.salePoints.map((salePoint, key) => <Checkbox key={key} text={salePoint.name + " - " + salePoint.location.name} name={salePoint.id} checked={this.service.containsSalePoint(salePoint.id)} onChange={this.onChangeSalePoint} />)
                                        }
                                    </div>
                                </>
                            }
                        </Form>
                        <Constraint key={this.state.plan.id} constraints={this.state.plan.constraints} planId={this.state.plan.id}/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Edit))