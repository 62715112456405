import React from 'react'
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2'
ChartJS.register(...registerables);

const Payers = () => {
    return (
        <div className="payers">
            <h2>Employees Summary</h2>
            <Line data={data} />
        </div>
    )
}

const data = {
    labels: ['2019', '2018', '2017', '2016', '2015', '2014', '2013'],
    datasets: [
        {
            label: 'New Employees',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#3DAB84',
            borderColor: '#3DAB84',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [65, 59, 80, 81, 56, 55, 40]
        },
        {
            label: 'Promotions',
            fill: false,
            lineTension: 0.1,
            backgroundColor: '#F51527',
            borderColor: '#F51527',
            borderCapStyle: 'butt',
            borderDash: [],
            borderDashOffset: 0.0,
            borderJoinStyle: 'miter',
            pointBorderColor: 'rgba(75,192,192,1)',
            pointBackgroundColor: '#fff',
            pointBorderWidth: 1,
            pointHoverRadius: 5,
            pointHoverBackgroundColor: 'rgba(75,192,192,1)',
            pointHoverBorderColor: 'rgba(220,220,220,1)',
            pointHoverBorderWidth: 2,
            pointRadius: 1,
            pointHitRadius: 10,
            data: [11, 40, 1, 50, 8, 9, 2]
        }
    ]
}

export default Payers