import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './plans.styles.scss'
import Service from './plans.service'
import NumberFormat from 'react-number-format'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeletePlan from './components/delete/delete.component'
import AuthHelper from '../../../utils/AuthHelper'

class Plans extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadPlans()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadPlans())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadPlans()
    }

    onLoadPrepaidPlansClicked = () => {
        this.setState({
            ...this.state, type: 1
        }, () => this.service.loadPlans())
    }

    onLoadInstantAccessPlansClicked = () => {
        this.setState({
            ...this.state, type: 2
        }, () => this.service.loadPlans())
    }

    toggleShowDelete = plan => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        planToDelete: plan
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        plans: this.state.plans.filter(plan => plan.id !== this.state.planToDelete.id)
    })

    render() {
        let authHelper = AuthHelper(this.props.auth)
        return (
            <>
                {this.state.loading && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeletePlan plan={this.state.planToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="plans">
                    <div className="header">
                        <div className="tabs">
                            <Link className={this.props.match.path.includes("prepaid") ? "active" : ""} to="/internet/plans/prepaid" onClick={this.onLoadPrepaidPlansClicked}>Prepaid Plans</Link>
                            <Link className={this.props.match.path.includes("instant-access") ? "active" : ""} to="/internet/plans/instant-access" onClick={this.onLoadInstantAccessPlansClicked}>Instant Access Plans</Link>
                        </div>
                        <div className="buttons">
                            {
                                authHelper.canCreatePlan() &&
                                <Link className="btn" to={this.props.match.path + "/create"}><img alt="" src={iconNew} />New Plan</Link>
                            }
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th>Contract Value</th>
                                <th>Uptime</th>
                                <th>Validity</th>
                                <th>SalePoints</th>
                                <th>Locations</th>
                                {
                                    (authHelper.canModifyPlan() || authHelper.canDeletePlan()) &&
                                    <th>Actions</th>
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.plans.map((plan, key) => <tr key={key}>
                                    <td>{plan.name}</td>
                                    <td>{plan.description}</td>
                                    <td><NumberFormat value={plan.amount} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td><NumberFormat value={plan.contractValue} displayType={'text'} thousandSeparator={true} prefix={'N'} renderText={value => value} /></td>
                                    <td><NumberFormat value={plan.uptime} displayType={'text'} thousandSeparator={true} renderText={value => value} /></td>
                                    <td><NumberFormat value={plan.validity} displayType={'text'} thousandSeparator={true} renderText={value => value} /></td>
                                    <td>{plan.type === 2 ? plan.salePointPlans.length : ''}</td>
                                    <td>{plan.type === 1 ? plan.locationPlans.length : ''}</td>
                                    {
                                        (authHelper.canModifyPlan() || authHelper.canDeletePlan()) &&
                                        <>
                                            <td>
                                                <div className="actions">
                                                    {authHelper.canModifyPlan() && <img alt="" src={iconEdit} onClick={() => this.props.history.push('/internet/plans/' + (this.state.type === 1 ? "prepaid/" + plan.id : "instant-access/" + plan.id))} />}
                                                    {authHelper.canDeletePlan() && <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(plan)} />}
                                                </div>
                                            </td>
                                        </>
                                    }
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Plans))