import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import Service from './edit.service'
import './edit.styles.scss'
import Dialog from '../../../../components/dialog/dialog.component'
import Loading from '../../../../components/loading/loading.component'
import Form from '../components/form/form.component'
import FormHelper from '../../../../utils/FormHelper'
import Checkbox from '../../../../components/inputs/checkbox/checkbox.component'

class Edit extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadProfile()
        this.service.loadNasList()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    onChangeNas = (nasId, checked) => this.service.onChangeNas(nasId)

    render() {
        return (
            <div className="edit">
                {(this.state.saving || this.state.loadingProfile || this.state.loadingNasList) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Edit Profile</h4>
                </div>
                <div className="card">
                    <div className={"flag " + this.state.flag.type}>
                        {this.state.flag.text}
                    </div>
                    <Form {...this.state} onChange={FormHelper(this, "profile").onChange} onSubmit={this.onSubmit} >
                        <h4>Allowed Nas</h4>
                        <div className="row-roles">
                            {
                                this.state.nasList.map((nas, key) => <Checkbox key={key} text={nas.nasname} name={nas.id} checked={this.service.containsNas(nas.id)} onChange={this.onChangeNas} />)
                            }
                        </div>
                    </Form>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Edit))