import React from 'react'
import { withRouter } from 'react-router'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row">
                    <div className="form-label">
                        <label>Firstname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="firstname" value={props.user.firstname} onChange={props.onChange} />
                        <span className="error">{props.errors.firstname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Lastname</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="lastname" value={props.user.lastname} onChange={props.onChange} />
                        <span className="error">{props.errors.lastname}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="form-label">
                        <label>Phone</label>
                    </div>
                    <div className="form-input">
                        <input type="phone" name="phone" value={props.user.phone} onChange={props.onChange} />
                        <span className="error">{props.errors.phone}</span>
                    </div>
                </div>
                {
                    props.user.showUsername ? <>
                        <div className="row">
                            <div className="form-label">
                                <label>Username</label>
                            </div>
                            <div className="form-input">
                                <input type="email" name="username" value={props.user.username} onChange={props.onChange} />
                                <span className="error">{props.errors.username}</span>
                            </div>
                        </div>
                        <div className="row">
                            <div className="form-label">
                                <label>Password</label>
                            </div>
                            <div className="form-input">
                                <input type="password" name="password" value={props.user.password} onChange={props.onChange} />
                                <span className="error">{props.errors.password}</span>
                            </div>
                        </div>
                    </> :
                        <div className="row" id="status">
                            <div className="form-label">
                                <label>Status</label>
                            </div>
                            <div className="form-input">
                                <select name="status" onChange={props.onChange} value={props.user.status}>
                                    <option value=""></option>
                                    <option value="0">Active</option>
                                    <option value="1">Suspended</option>
                                </select>
                                <span className="error">{props.errors.status}</span>
                            </div>
                        </div>
                }
                {props.children}
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/security/users/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)