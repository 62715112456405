import axios from 'axios'
import {API_ENDPOINT} from '../../config'

const all = (instance, params) => {
    instance.setState({ ...instance.state, loadingProfiles: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/profiles",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadProfilesResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingProfiles: false
            })
            alert(error)
        })
}

const handleLoadProfilesResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingProfiles: false,
                profiles: response.data.data.profiles,
                total: response.data.data.size
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingProfiles: false
            })
            alert(response.data.message)
    }
}

const one = (instance, params) => {
    instance.setState({ ...instance.state, loadingProfile: true })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/profile",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: params
    }).then(response => handleLoadProfileResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingProfile: false
            })
            alert(error)
        })
}

const handleLoadProfileResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingProfile: false,
                profile: response.data.data.profile
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingProfile: false
            })
            alert(response.data.message)
    }
}

const ProfileDatasource = () => {
    return {
        all: (instance, params) => all(instance, params),
        one: (instance, params) => one(instance, params)
    }
}

export default ProfileDatasource