import PermissionDatasource from '../../../data/datasource/permission.datasource'

const getDefaultState = () => {
    return {
        loadingPermissions: false,
        permissions: [],
        filter: {
            column: "",
            value: ""
        },
        showDelete: false,
        permissionToDelete: null,
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadPermissions = instance => {
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    PermissionDatasource().all(instance, {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: criteria
    })
}

const Service = instance => {
    return {
        loadPermissions: () => loadPermissions(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service