import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { loadPermissions, isValid, onChangeRolePermission, containsRolePermission } from '../common.serivce'
import RoleDatasource from '../../../../data/datasource/role.datasource'

const getDefaultState = instance => {
    return {
        saving: false,
        role: {
            id: instance.props.match.params.id,
            name: "",
            rolePermissions: []
        },
        errors: {},
        permissions: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadRole = instance => {
    RoleDatasource().one(instance, {
        id: instance.props.match.params.id
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/role/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.role
    }).then(response => {
        if (response.status === 200) {
            instance.setState({
                ...instance.state,
                saving: false,
                flag: getFlag(response.data)
            })
        }
    }).catch(error => alert(error))
}

const getFlag = response => {
    switch (response.status) {
        case 200:
            return {
                type: "success",
                text: "Role Updated"
            }
        default:
            return {
                type: "error",
                text: response.message
            }
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        loadPermissions: () => loadPermissions(instance),
        isValid: () => isValid(instance),
        onChangeRolePermission: permissionId => onChangeRolePermission(instance, permissionId),
        containsRolePermission: permissionId => containsRolePermission(instance, permissionId),
        save: () => save(instance),
        loadRole: () => loadRole(instance)
    }
}

export default Service