import React from 'react'
import { Switch, Route } from "react-router-dom"
import Permissions from './permissions.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'
import './common.styles.scss'

const PermissionController = () => {
    return (
        <div className="permission">
            <Switch>
                <Route path="/security/permission/create" component={Create} />
                <Route path="/security/permission/all" component={Permissions} />
                <Route path="/security/permission/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default PermissionController