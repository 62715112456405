import axios from 'axios'
import { IsValid } from '../common.service'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = () => {
    return {
        saving: false,
        nas: {
            nasname: "",
            shortname: "",
            ports: "",
            secret: "",
            description: ""
        },
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/nas/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.nas
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...getDefaultState(),
                saving: false,
                flag: {
                    type: "success",
                    text: "Created successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        isValid: () => IsValid(instance),
        save: () => save(instance)
    }
}

export default Service