import React from 'react'
import Filter from '../../../components/filter/simple/simple-filter.component'

const Filters = props => {
    return (
        <Filter columns={columns} onChange={props.onChange} onSubmit={props.onSubmit} {...props.filter} />
    )
}

const columns = [
    {
        name: "staffId",
        label: "Staff ID",
        type: "S"
    },
    {
        name: "confidentiaFileId",
        label: "Conf. File ID",
        type: "S"
    },
    {
        name: "name",
        label: "Name",
        type: "S"
    },
    {
        name: "status",
        label: "Status",
        type: "C",
        options: [
            {
                label: "Active",
                value: 0
            },
            {
                label: "Retired",
                value: 1
            },
            {
                label: "Resigned",
                value: 2
            },
            {
                label: "Deceased",
                value: 3
            },
            {
                label: "On Leave of Absence",
                value: 4
            },
            {
                label: "On Study Leave",
                value: 5
            },
            {
                label: "Voluntary Retired",
                value: 6
            },
            {
                label: "Dismissed",
                value: 7
            }
        ]
    },
]

export default Filters