import axios from 'axios'
import { IsValid } from '../common.service'
import PermissionDatasource from '../../../../data/datasource/permission.datasource'
import { API_ENDPOINT } from '../../../../config'

const getDefaultState = instance => {
    return {
        loadingPermission: false,
        saving: false,
        permission: {
            id: instance.props.match.params.id,
            name: "",
            group: ""
        },
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const loadPermission = instance => {
    PermissionDatasource().one(instance, {
        id: instance.props.match.params.id
    })
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/permission/update",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.permission
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                saving: false,
                flag: {
                    type: "success",
                    text: "Updated successfully"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance),
        isValid: () => IsValid(instance),
        loadPermission: () => loadPermission(instance),
        save: () => save(instance)
    }
}

export default Service