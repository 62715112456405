import axios from 'axios'
import { API_ENDPOINT } from '../../../config'

const getDefaultState = () => {
    return {
        loading: false,
        report: {
            individuals: 0,
            corporates: 0,
            increase: "0"
        }
    }
}

const loadReport = instance => {
    instance.setState({ ...instance.state, loading: true })
    axios({
        method: 'get',
        url: API_ENDPOINT + "/payer/report",
        headers : {
            "Authorization": "Bearer " + instance.props.auth.authorization
        }
    }).then(response => handleLoadReportResponse(instance, response))
    .catch(error => {
        alert(error)
        instance.setState({ ...instance.state, loading: false })
    })
}

const handleLoadReportResponse = (instance, response) => {
    switch(response.data.status.code){
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                report: response.data.data
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loading: false
            })
            alert(response.data.status.message)
    }
}

const Service = instance => {
    return {
        loadReport: () => loadReport(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service