import React from 'react'

const Form = (props) => {
    return (
        <form>
            <div className="col">
                <div className="inputs">
                    <div className="row" id="profileId">
                        <div className="form-label">
                            <label>Profile</label>
                        </div>
                        <div className="form-input">
                            <select name="profileId" onChange={props.onChange} value={props.profileId}>
                                <option value=""></option>
                                {
                                    props.profiles.map((profile, key) => <option key={key} value={profile.id}>{profile.name}</option>)
                                }
                            </select>
                            <span className="error">{props.errors.profileId}</span>
                        </div>
                    </div>
                    <div className="row" id="start">
                        <div className="form-label">
                            <label>Start time</label>
                        </div>
                        <div className="form-input">
                            <div className="time">
                                <select name="startH" onChange={props.onChange} defaultValue={props.startH}>
                                    <option value=""></option>
                                    {
                                        getRange(23).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                                <select name="startM" onChange={props.onChange} defaultValue={props.startM}>
                                    <option value=""></option>
                                    {
                                        getRange(59).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                                <select name="startS" onChange={props.onChange} defaultValue={props.startS}>
                                    <option value=""></option>
                                    {
                                        getRange(59).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                            </div>
                            <span className="error">{props.errors.start}</span>
                        </div>
                    </div>
                    <div className="row" id="end">
                        <div className="form-label">
                            <label>End time</label>
                        </div>
                        <div className="form-input">
                            <div className="time">
                                <select name="endH" onChange={props.onChange} defaultValue={props.endH}>
                                    <option value=""></option>
                                    {
                                        getRange(23).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                                <select name="endM" onChange={props.onChange} defaultValue={props.endM}>
                                    <option value=""></option>
                                    {
                                        getRange(59).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                                <select name="endS" onChange={props.onChange} defaultValue={props.endS}>
                                    <option value=""></option>
                                    {
                                        getRange(59).map((value, key) => <option key={key} value={value}>{value}</option>)
                                    }
                                </select>
                            </div>
                            <span className="error">{props.errors.end}</span>
                        </div>
                    </div>
                </div>
                {props.children}
            </div>
            <div className="actions">
                <button type="reset" onClick={props.onCancel}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

const getRange = max => {
    let values = []
    for (let i = 0; i <= max; i++) {
        values.push(i < 10 ? "0" + String(i) : String(i))
    }
    return values
}

export default Form