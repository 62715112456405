import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Dialog from '../../../components/dialog/dialog.component'
import Loading from '../../../components/loading/loading.component'
import { connect } from 'react-redux'
import iconNew from '../../../assets/icons/new-light.svg'
import Pagination from '../../../components/pagination/pagination.component'
import './sites.styles.scss'
import Service from './sites.service'
import { FormatDate } from '../../../utils/UIHelper'
import iconDelete from '../../../assets/icons/delete-red.svg'
import iconEdit from '../../../assets/icons/edit-dark.svg'
import DeleteSite from './components/delete/delete.component'

class Sites extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadSites()
    }

    onPageChange = page => {
        this.setState({
            ...this.state,
            currentPage: page
        }, () => this.service.loadSites())
    }

    onFilterChange = e => {
        this.setState({
            ...this.state,
            filter: {
                ...this.state.filter,
                value: "",
                [e.target.name]: e.target.value
            }
        })
    }

    onFilterSubmit = e => {
        e.preventDefault()
        this.service.loadSites()
    }

    toggleShowDelete = site => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        siteToDelete: site
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        sites: this.state.sites.filter(site => site.id !== this.state.siteToDelete.id)
    })

    render() {
        return (
            <>
                {this.state.loadingSites && <Dialog><Loading /></Dialog>}
                {this.state.showDelete && <Dialog><DeleteSite site={this.state.siteToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                <div className="sites">
                    <div className="header">
                        <h4>Sites</h4>
                        <div className="buttons">
                            <Link className="btn" to="/internet/site/create"><img alt="" src={iconNew} />New Site</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Area</th>
                                <th>Location</th>
                                <th>Created</th>
                                <th>Updated</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.sites.map((site, key) => <tr key={key}>
                                    <td>{site.name}</td>
                                    <td>{site.area}</td>
                                    <td>{site.location.name}</td>
                                    <td>{FormatDate(site.created * 1000)}</td>
                                    <td>{FormatDate(site.updated * 1000)}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.props.history.push('/internet/site/' + site.id)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(site)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                    {
                        this.state.total > 0 && <Pagination totalItemsCount={this.state.total} currentIndex={this.state.currentPage} itemsPerPage={this.state.pageSize} onChange={this.onPageChange} />
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Sites))