import React from 'react'
import { Switch, Route } from "react-router-dom"
import Roles from './roles.page'
import CreateRole from './create/create.page'
import EditRole from './edit/edit.page'

const RoleController = () => {
    return (
        <Switch>
            <Route path="/security/roles/create" component={CreateRole} />
            <Route exact path="/security/roles/all" component={Roles} />
            <Route path="/security/roles/:id" component={EditRole} />
        </Switch>
    )
}

export default RoleController