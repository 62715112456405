import NasDatasource from '../../../data/datasource/nas.datasource'

const getDefaultState = () => {
    return {
        loadingNasList: false,
        nasList: [],
        filter: {
            column: "",
            value: ""
        },
        showDelete: false,
        nasToDelete: null,
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadNasList = instance => {
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    NasDatasource().all(instance, {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: criteria
    })
}

const Service = instance => {
    return {
        loadNasList: () => loadNasList(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service