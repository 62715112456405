import axios from 'axios'
import {API_ENDPOINT} from '../../config'

export const LoadLocations = instance => {
    instance.setState({ ...instance.state, loadingLocations: true })
    let criteria = {}
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/locations",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadLocationsResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                loadingLocations: false
            })
            alert(error)
        })
}

const handleLoadLocationsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loadingLocations: false,
                locations: response.data.data.locations
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            instance.setState({
                ...instance.state,
                loadingLocations: false
            })
            alert(response.data.message)
    }
}