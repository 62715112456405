import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import Service from './edit-constraint.service'
import Form from '../form/form.component'
import FormHelper from '../../../../../../../../utils/FormHelper'
import Checkbox from '../../../../../../../../components/inputs/checkbox/checkbox.component'
import Dialog from '../../../../../../../../components/dialog/dialog.component'
import Loading from '../../../../../../../../components/loading/loading.component'

class EditConstraint extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        this.service.loadProfiles()
    }

    onSubmit = (e) => {
        e.preventDefault()
        this.service.isValid() && this.service.save()
    }

    onChangeDay= (day, checked) => this.service.onChangeDay(Number(day))

    render() {
        return (
            <div className="edit-constraint card">
                {(this.state.saving || this.state.loadingProfiles) && <Dialog><Loading /></Dialog>}
                <div className="header">
                    <h4>Edit Constraint</h4>
                </div>
                <div className={"flag " + this.state.flag.type}>
                    {this.state.flag.text}
                </div>
                <Form {...this.state} onChange={FormHelper(this).onChange} onSubmit={this.onSubmit} onCancel={this.props.onClose} >
                    <div className="row-days">
                        <Checkbox text="Sun" name="0" checked={this.service.containsDay(0)} onChange={this.onChangeDay} />
                        <Checkbox text="Mon" name="1" checked={this.service.containsDay(1)} onChange={this.onChangeDay} />
                        <Checkbox text="Tue" name="2" checked={this.service.containsDay(2)} onChange={this.onChangeDay} />
                        <Checkbox text="Wed" name="3" checked={this.service.containsDay(3)} onChange={this.onChangeDay} />
                        <Checkbox text="Thu" name="4" checked={this.service.containsDay(4)} onChange={this.onChangeDay} />
                        <Checkbox text="Fri" name="5" checked={this.service.containsDay(5)} onChange={this.onChangeDay} />
                        <Checkbox text="Sat" name="6" checked={this.service.containsDay(6)} onChange={this.onChangeDay} />
                    </div>
                </Form>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(EditConstraint))