import React from 'react'
import { withRouter } from 'react-router-dom'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="id">
                    <div className="form-label">
                        <label>Id</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="id" value={props.permission.id} onChange={props.onChange} />
                        <span className="error">{props.errors.id}</span>
                    </div>
                </div>
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.permission.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="group">
                    <div className="form-label">
                        <label>Group</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="group" value={props.permission.group} onChange={props.onChange} />
                        <span className="error">{props.errors.group}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/security/permission/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)