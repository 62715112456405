import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import Service from './payer.service'
import iconAvatarGreen from '../../../assets/icons/dashboard-avatar-green.svg'
import iconAvatarRed from '../../../assets/icons/dashboard-avatar-red.svg'
import iconAvatarYellow from '../../../assets/icons/dashboard-avatar-yellow.svg'
import iconArrow from '../../../assets/icons/dashboard-card-arrow.svg'
import Loading from '../../../components/loading/loading.component'
import './payer.styles.scss'
import NumberFormat from 'react-number-format'

class PayerReport extends React.Component {
    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    componentDidMount() {
        //this.service.loadReport()
    }

    render() {
        if(this.state.loading){
            return (
                <div className="payer-report">
                    <Loading/>
                </div>
            )
        }
        return (
            <div className="payer-report">
                <div className="cards">
                    <div className="_card green">
                        <div className="content">
                            <div>
                                <h3><NumberFormat value="" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value} /></h3>
                                <p>New Employees</p>
                            </div>
                            <img alt="" src={iconAvatarGreen} />
                        </div>
                        <div className="footer">
                            <Link to="/">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                    <div className="_card red">
                        <div className="content">
                            <div>
                                <h3><NumberFormat value="" displayType={'text'} thousandSeparator={true} prefix={''} renderText={value => value} /></h3>
                                <p>Employees Due for Retirement</p>
                            </div>
                            <img alt="" src={iconAvatarRed} />
                        </div>
                        <div className="footer">
                            <Link to="/">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                    <div className="_card yellow">
                        <div className="content">
                            <div>
                                <h3>0.4%</h3>
                                <p>Increase in last quarter</p>
                            </div>
                            <img alt="" src={iconAvatarYellow} />
                        </div>
                        <div className="footer">
                            <Link to="/">
                                <i>See all</i>
                                <img alt="" src={iconArrow} />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(PayerReport))