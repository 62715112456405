import React from 'react'
import { Switch, Route } from "react-router-dom"
import NasList from './naslist.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const LocationController = () => {
    return (
        <div className="location">
            <Switch>
                <Route path="/internet/nas/create" component={Create} />
                <Route path="/internet/nas/all" component={NasList} />
                <Route path="/internet/nas/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default LocationController