import React from 'react'
import { withRouter } from 'react-router-dom'
import './form.styles.scss'

const Form = (props) => {
    return (
        <form>
            <div className="inputs">
                <div className="row" id="name">
                    <div className="form-label">
                        <label>Name</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="name" value={props.site.name} onChange={props.onChange} />
                        <span className="error">{props.errors.name}</span>
                    </div>
                </div>
                <div className="row" id="area">
                    <div className="form-label">
                        <label>Area</label>
                    </div>
                    <div className="form-input">
                        <input type="text" name="area" value={props.site.area} onChange={props.onChange} />
                        <span className="error">{props.errors.area}</span>
                    </div>
                </div>
                <div className="row" id="locationId">
                    <div className="form-label">
                        <label>Location</label>
                    </div>
                    <div className="form-input">
                        <select name="locationId" onChange={props.onChange} value={props.site.locationId}>
                            <option value=""></option>
                            {
                                props.locations.map((location, key) => <option key={key} value={location.id}>{location.name}</option>)
                            }
                        </select>
                        <span className="error">{props.errors.locationId}</span>
                    </div>
                </div>
            </div>
            <div className="actions">
                <button type="reset" onClick={() => props.history.push('/internet/site/all')}>Cancel</button>
                <button type="submit" onClick={props.onSubmit}>Save</button>
            </div>
        </form>
    )
}

export default withRouter(Form)