
const getDefaultState = instance => {
    return {
        activeCustomerPlans: instance.props.activeCustomerPlans,
        showDelete: false,
        showEdit: false
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service