import React from 'react'
import { Switch, Route } from "react-router-dom"
import PlanController from './plans/plan.controller'
import SalePointController from './salepoints/salepoint.controller'
import LocationController from './locations/location.controller'
import NasController from './nas/nas.controller'
import SiteController from './site/site.controller'
import ProfileController from './profile/profile.controller'
import './common.styles.scss'

const InternetController = () => {
    return (
        <div className="internet">
            <Switch>
                <Route path="/internet/plans" component={PlanController} />
                <Route path="/internet/nas" component={NasController} />
                <Route path="/internet/site" component={SiteController} />
                <Route path="/internet/profile" component={ProfileController} />
                <Route path="/internet/locations" component={LocationController} />
                <Route path="/internet/salepoints" component={SalePointController} />
            </Switch>
        </div>
    )
}

export default InternetController