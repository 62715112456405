import axios from 'axios'
import { API_ENDPOINT } from '../../../../../../../../config'
import {loadProfiles, isValid, onChangeDay, containsDay} from '../common.service'

const getDefaultState = () => {
    return {
        loadingProfiles: false,
        saving: false,
        profileId: "",
        start: "",
        startH: "",
        startM: "",
        startS: "",
        end: "",
        endH: "",
        endM: "",
        endS: "",
        days: [],
        profiles: [],
        errors: {},
        flag: {
            type: "",
            text: ""
        }
    }
}

const save = (instance) => {
    instance.setState({
        ...instance.state,
        saving: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/plan/constraint/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            planId: instance.props.planId,
            profileId: instance.state.profileId,
            days: instance.state.days,
            start: new Date(2021, 9, 30, instance.state.startH, instance.state.startM, instance.state.startM).getTime()/1000,
            end: new Date(2021, 9, 30, instance.state.endH, instance.state.endM, instance.state.endM).getTime()/1000
        }
    }).then(response => handleSaveResponse(instance, response))
        .catch(error => {
            instance.setState({
                ...instance.state,
                saving: false
            })
            alert(error)
        })
}

const handleSaveResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "success",
                    text: "Saved successfully"
                }
            })
            instance.props.onConstraintReceived(response.data.data.planConstraint)
            instance.props.onClose()
            break
        default:
            instance.setState({
                ...instance.state,
                saving: false,
                flag: {
                    type: "error",
                    text: response.data.message
                }
            })
            alert(response.data.message)
    }
}

const Service = instance => {
    return {
        getDefaultState: () => getDefaultState(),
        loadProfiles: () => loadProfiles(instance),
        isValid: () => isValid(instance),
        onChangeDay: day => onChangeDay(instance, day),
        containsDay: day => containsDay(instance, day),
        save: () => save(instance)
    }
}

export default Service