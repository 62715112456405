import axios from 'axios'
import Validation from '../../../data/validation/validation'
import { PhoneValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import { API_ENDPOINT } from '../../../config'

export const loadLocations = instance => {
    instance.setState({ ...instance.state, loading: true })
    let criteria = {}
    axios({
        method: 'post',
        url: API_ENDPOINT + "/services/internet/locations",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: {
            offset: 0,
            limit: 100,
            criteria: criteria
        }
    }).then(response => handleLoadLocationsResponse(instance, response))
        .catch(error => alert(error))
}

const handleLoadLocationsResponse = (instance, response) => {
    switch (response.data.status) {
        case 200:
            instance.setState({
                ...instance.state,
                loading: false,
                locations: response.data.data.locations
            })
            break;
        case 403:
            instance.props.history.push('/auth/login')
            break;
        default:
            alert(response.data.message)
    }
}

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })
    if (typeof instance.state.type !== "undefined") {
        validation.addValidationRule(StringValidationRule, instance.state.type, (error) => instance.state.errors.type = error, { min: { value: 1, error: "Invalid" }, max: { value: 1, error: "Invalid" } })
    }
    validation.addValidationRule(PhoneValidationRule, instance.state.phone, (error) => instance.state.errors.phone = error, { allowNull: true })
    validation.addValidationRule(StringValidationRule, instance.state.smsSender, (error) => instance.state.errors.smsSender = error, { min: { value: 0, error: "Invalid" }, max: { value: 10, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.address, (error) => instance.state.errors.address = error, { min: { value: 0, error: "Invalid" }, max: { value: 100, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.city, (error) => instance.state.errors.city = error, { min: { value: 0, error: "Invalid" }, max: { value: 30, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.locationId, (error) => instance.state.errors.locationId = error, { min: { value: 20, error: "Invalid" }, max: { value: 45, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}