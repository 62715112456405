import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import iconNew from '../../../../../../assets/icons/new-light.svg'
import './constraint.styles.scss'
import Service from './constraint.service'
import Dialog from '../../../../../../components/dialog/dialog.component'
import AddConstraint from './components/add-constraint/add-constraint.component'
import EditConstraint from './components/edit-constraint/edit-constraint.component'
import iconDelete from '../../../../../../assets/icons/delete-red.svg'
import iconEdit from '../../../../../../assets/icons/edit-dark.svg'
import DeleteConstraint from './components/delete/delete.component'

class Constraint extends React.Component {

    constructor(props) {
        super(props)
        this.service = Service(this)
        this.state = this.service.getDefaultState()
    }

    getDaysLabel = days => {
        let label = ""
        for (let i = 0; i < days.length; i++) {
            label += this.getDayLabel(days[i])
            if (i < (days.length - 1)) {
                label += ", "
            }
        }
        return label
    }

    getDayLabel = day => {
        switch (day) {
            case 0:
                return "Sun"
            case 1:
                return "Mon"
            case 2:
                return "Tue"
            case 3:
                return "Wed"
            case 4:
                return "Thu"
            case 5:
                return "Fri"
            case 6:
                return "Sat"
            default:
                return "Unknown"
        }
    }

    toggleShowAdd = () => this.setState({
        ...this.state,
        showAdd: !this.state.showAdd
    })

    toggleShowEdit = () => this.setState({
        ...this.state,
        showEdit: !this.state.showEdit
    })

    onConstraintReceived = constraint => {
        let constraints = this.state.constraints
        constraints.push(constraint)
        this.setState({
            ...this.state,
            constraints: constraints
        })
    }

    onConstraintUpdated = constraint => {
        let constraints = this.state.constraints
        for (let i = 0; i < constraints.length; i++) {
            if (constraints[i].id === constraint.id) {
                constraints[i] = constraint
            }
        }
        this.setState({
            ...this.state,
            constraints: constraints
        })
    }

    onShowAddClick = e => {
        e.preventDefault()
        this.toggleShowAdd()
    }

    onShowEditClick = constraint => {
        this.setState({
            ...this.state,
            constraint: constraint,
            showEdit: true
        })
    }

    toggleShowDelete = constraint => this.setState({
        ...this.state,
        showDelete: !this.state.showDelete,
        constraintToDelete: constraint
    })

    onDelete = () => this.setState({
        ...this.state,
        showDelete: false,
        constraints: this.state.constraints.filter(constraint => constraint.id !== this.state.constraintToDelete.id)
    })

    render() {
        return (
            <>
                <div className="constraint plans">
                    {this.state.showAdd && <Dialog><AddConstraint planId={this.props.planId} onClose={this.toggleShowAdd} onConstraintReceived={this.onConstraintReceived} /></Dialog>}
                    {this.state.showEdit && <Dialog><EditConstraint {...this.state.constraint} onClose={this.toggleShowEdit} onConstraintUpdated={this.onConstraintUpdated} /></Dialog>}
                    {this.state.showDelete && <Dialog><DeleteConstraint constraint={this.state.constraintToDelete} onDelete={this.onDelete} onCancel={() => this.toggleShowDelete(null)} /></Dialog>}
                    <div className="header">
                        <h4>Constraints</h4>
                        <div className="buttons">
                            <Link className="btn" to="" onClick={this.onShowAddClick}><img alt="" src={iconNew} />New Constraint</Link>
                        </div>
                    </div>
                    <table>
                        <thead>
                            <tr>
                                <th>Profile</th>
                                <th>Start</th>
                                <th>End</th>
                                <th>Days</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.constraints.map((constraint, key) => <tr key={key}>
                                    <td>{constraint.profile.name}</td>
                                    <td>{constraint.start}</td>
                                    <td>{constraint.end}</td>
                                    <td>{this.getDaysLabel(constraint.days)}</td>
                                    <td>
                                        <div className="actions">
                                            <img alt="" src={iconEdit} onClick={() => this.onShowEditClick(constraint)} />
                                            <img alt="" src={iconDelete} onClick={() => this.toggleShowDelete(constraint)} />
                                        </div>
                                    </td>
                                </tr>)
                            }
                        </tbody>
                    </table>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        auth: state.auth
    }
}

export default connect(mapStateToProps)(withRouter(Constraint))