const hasPermission = (auth, permissions) => {
    permissions.push(1)
    for(let i = 0; i < auth.rolePermissions.length; i++){
        if(permissions.includes(auth.rolePermissions[i].permissionId)){
            return true
        }
    }
    return true
}

const canAddCustomerPlan = auth => {
    return hasPermission(auth, [84])
}

const canCreateCustomer = auth => {
    return hasPermission(auth, [27])
}

const canChangeCustomerPlan = auth => {
    return hasPermission(auth, [])
}

const canEditCustomer = auth => {
    return hasPermission(auth, [22])
}

const canModifyCustomerPlan = auth => {
    return hasPermission(auth, [24])
}

const canDeleteCustomerPlan = auth => {
    return hasPermission(auth, [25])
}

const canCreatePlan = auth => {
    return hasPermission(auth, [54])
}

const canModifyPlan = auth => {
    return hasPermission(auth, [52])
}

const canDeletePlan = auth => {
    return hasPermission(auth, [53])
}

const AuthHelper = auth => {
    return {
        hasPermission: permissions => hasPermission(auth, permissions),
        canCreateCustomer: permissions => canCreateCustomer(auth, permissions),
        canAddCustomerPlan: permissions => canAddCustomerPlan(auth, permissions),
        canChangeCustomerPlan: permissions => canChangeCustomerPlan(auth, permissions),
        canEditCustomer: permissions => canEditCustomer(auth, permissions),
        canModifyCustomerPlan: permissions => canModifyCustomerPlan(auth, permissions),
        canDeleteCustomerPlan: permissions => canDeleteCustomerPlan(auth, permissions),
        canCreatePlan: permissions => canCreatePlan(auth, permissions),
        canModifyPlan: permissions => canModifyPlan(auth, permissions),
        canDeletePlan: permissions => canDeletePlan(auth, permissions)
    }
}

export default AuthHelper