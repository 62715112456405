import Validation from '../../../data/validation/validation'
import { NumberValidationRule, StringValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'

export const IsValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(NumberValidationRule, instance.state.permission.id, (error) => instance.state.errors.id = error, { min: { value: 1, error: "Invalid" }, max: { value: 500, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.permission.name, (error) => instance.state.errors.name = error, { min: { value: 2, error: "Invalid" }, max: { value: 30, error: "Invalid" } })
    validation.addValidationRule(StringValidationRule, instance.state.permission.group, (error) => instance.state.errors.group = error, { min: { value: 2, error: "Invalid" }, max: { value: 20, error: "Invalid" } })

    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}