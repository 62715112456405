import React from 'react'
import { Switch, Route } from "react-router-dom"
import Sites from './sites.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'
import './common.styles.scss'

const SiteController = () => {
    return (
        <div className="site">
            <Switch>
                <Route path="/internet/site/create" component={Create} />
                <Route path="/internet/site/all" component={Sites} />
                <Route path="/internet/site/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default SiteController