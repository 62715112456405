import React from 'react'
import { Switch, Route } from "react-router-dom"
import SalePoints from './salepoints.page'
import Create from './create/create.page'
import Edit from './edit/edit.page'

const SalePointController = () => {
    return (
        <div className="salepoint">
            <Switch>
                <Route path="/internet/salepoints/create" component={Create} />
                <Route path="/internet/salepoints/all" component={SalePoints} />
                <Route path="/internet/salepoints/:id" component={Edit} />
            </Switch>
        </div>
    )
}

export default SalePointController