import axios from 'axios'
import { API_ENDPOINT } from '../../../../config'
import { loadPermissions, isValid, onChangeRolePermission, containsRolePermission } from '../common.serivce'

const getDefaultState = () => {
    return {
        creating: false,
        role: {
            name: "",
            rolePermissions: []
        },
        errors: {},
        permissions: [],
        flag: {
            type: "",
            text: ""
        }
    }
}

const createRole = (instance) => {
    instance.setState({
        ...instance.state,
        creating: true
    })
    axios({
        method: 'post',
        url: API_ENDPOINT + "/user/role/create",
        headers: {
            "Authorization": instance.props.auth.authorization
        },
        data: instance.state.role
    }).then(response => {
        if (response.status === 200) {
            handleCreateRoleResponse(instance, response.data)
        }
    }).catch(error => alert(error))
}

const handleCreateRoleResponse = (instance, response) => {
    switch (response.status) {
        case 200:
            instance.setState({
                ...instance.state,
                role: {
                    name: "",
                    rolePermissions: []
                },
                flag: {
                    type: "success",
                    text: "Role Created"
                }
            })
            break
        default:
            instance.setState({
                ...instance.state,
                creating: false,
                flag: {
                    type: "error",
                    text: response.message
                }
            })
    }
}

const Service = instance => {
    return {
        getDefaultState: getDefaultState,
        loadPermissions: () => loadPermissions(instance),
        isValid: () => isValid(instance),
        createRole: () => createRole(instance),
        onChangeRolePermission: permissionId => onChangeRolePermission(instance, permissionId),
        containsRolePermission: permissionId => containsRolePermission(instance, permissionId)
    }
}

export default Service