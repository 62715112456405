import Validation from '../../../data/validation/validation'
import { StringValidationRule, EmailValidationRule, PhoneValidationRule } from '../../../data/validation/rules'
import { ScrollIntoFirstError } from '../../../utils/UIHelper'
import RoleDatasource from '../../../data/datasource/role.datasource'

export const isValid = instance => {
    let validation = new Validation()
    let errors = instance.state.errors
    validation.addValidationRule(StringValidationRule, instance.state.user.firstname, (error) => errors.firstname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(StringValidationRule, instance.state.user.lastname, (error) => errors.lastname = error, { min: { value: 2, error: "Too short" }, max: { value: 25, error: "Too long" } })
    validation.addValidationRule(PhoneValidationRule, instance.state.user.phone, (error) => errors.phone = error, { allowNull: false })
    if (instance.state.user.showUsername) {
        validation.addValidationRule(EmailValidationRule, instance.state.user.username, (error) => errors.username = error, { allowNull: false })
        validation.addValidationRule(StringValidationRule, instance.state.user.password, (error) => errors.password = error, { min: { value: 6, error: "Too short" }, max: { value: 40, error: "Too long" } })
    }
    if (typeof instance.state.user.status !== 'undefined') {
        validation.addValidationRule(StringValidationRule, instance.state.user.status, (error) => errors.status = error, { min: { value: 1, error: "Too short" }, max: { value: 1, error: "Too long" } })
    }
    let validate = validation.validate()
    let stateUpdate = {
        errors: errors,
        flag: {
            type: validate ? "" : "error",
            text: validate ? "" : "Validation Failure"
        }
    }
    ScrollIntoFirstError(errors)
    instance.setState({
        ...instance.state,
        ...stateUpdate
    })
    return validate
}

export const loadRoles = instance => {
    RoleDatasource().all(instance, {
        offset: 0,
        limit: 100,
        criteria: {}
    })
}

export const onChangeUserRole = (instance, roleId) => {
    let userRoles = instance.state.user.userRoles
    if (containsUserRole(instance, roleId)) {
        userRoles = instance.state.role.userRoles.filter(userRole => userRole.role.id !== roleId)
    }
    else {
        userRoles.push({
            role: {
                id: roleId
            }
        })
    }
    instance.setState({
        ...instance.state,
        user: {
            ...instance.state.user,
            userRoles: userRoles
        }
    })
}

export const containsUserRole = (instance, roleId) =>  {
    for(let i = 0; i < instance.state.user.userRoles.length; i++){
        if(instance.state.user.userRoles[i].role.id === roleId){
            return true
        }
    }
    return false
}
