import ProfileDatasource from '../../../data/datasource/profile.datasource'

const getDefaultState = () => {
    return {
        loadingProfiles: false,
        profiles: [],
        filter: {
            column: "",
            value: ""
        },
        showDelete: false,
        profileToDelete: null,
        selectedIndex: -1,
        currentPage: 1,
        pageSize: 10,
        total: 0
    }
}

const loadProfiles = instance => {
    let criteria = {}
    if (instance.state.filter.column.length > 0 && instance.state.filter.value.length > 0) {
        criteria = {
            [instance.state.filter.column]: instance.state.filter.value
        }
    }

    ProfileDatasource().all(instance, {
        offset: (instance.state.currentPage - 1) * instance.state.pageSize,
        limit: instance.state.pageSize,
        criteria: criteria
    })
}

const Service = instance => {
    return {
        loadProfiles: () => loadProfiles(instance),
        getDefaultState: () => getDefaultState(instance)
    }
}

export default Service